<template>
  <div v-if="userEducationalStagesData" class="table-container">
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th colspan="5">{{ $t("UserEducationalStages.data") }}</th>
        </tr>
        <tr>
          <th>{{ $t("general.image") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th class="cell-lg">{{ $t("general.name") }}</th>
          <th class="cell-lg">{{ $t("description") }}</th>
          <th class="cell-lg">{{ $t("notes") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(userEducationalStage, index) in userEducationalStagesData"
          :key="index"
        >
          <td>{{ ++index + filterData.currentIndex }}</td>
          <td>
            <img
              class="item-img-table"
              :src="
                fullPathFileFromServer(
                  userEducationalStage.userEducationalStageImagePath,
                  defaultImg
                )
              "
              :onerror="`this.src='${defaultImg}'`"
            />
          </td>
          <td>{{ isDataExist(userEducationalStage.fullCode) }}</td>
          <td>
            {{
              isDataExist(
                userEducationalStage.educationalStageInfo
                  .educationalStageNameCurrent
              )
            }}
          </td>
          <td>
            {{
              isDataExist(
                userEducationalStage.educationalStageInfo
                  .educationalStageDescriptionCurrent
              )
            }}
          </td>

          <td>
            {{
              isDataExist(
                userEducationalStage.educationalStageInfo.educationalStageNotes
              )
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  isDataExist,
  fullPathFileFromServer,
} from "./../../../utils/functions";

export default {
  name: "UserEducationalStagesTable",

  components: {},
  props: ["userEducationalStagesData", "filterData", "defaultImg"],
  methods: {
    isDataExist,
    fullPathFileFromServer,
  },
};
</script>
