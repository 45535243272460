import BaseEntityModel from "./../general/BaseEntityModel";
import defaultImg from "@/assets/images/userEducationalStages.svg";
import UserInfoData from "./../users/UserInfoData";
import EducationalStageInfo from "./../settings/settingsOthers/educationalStages/EducationalStageInfo";

export default class UserEducationalStage extends BaseEntityModel {
  icon = defaultImg;
  defaultImg = defaultImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setInitialValueBaseEntityModel();
    this.userEducationalStageToken = "";
    this.userToken = "";
    this.educationalStageToken = "";
    this.userEducationalStageNotes = "";
    this.userEducationalStageImageIsDefault = false;
    this.userEducationalStageImagePath = "";
    this.userEducationalStageImageSizeBytes = 0;
    this.userEducationalStageImageSizeTextCurrent = "";
    this.userEducationalStageImageSizeTextAr = "";
    this.userEducationalStageImageSizeTextEn = "";
    this.userEducationalStageImageSizeTextUnd = "";
    this.userInfoData = new UserInfoData();
    this.educationalStageInfo = new EducationalStageInfo();
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModel(data);
      this.userEducationalStageToken = data.userEducationalStageToken ?? "";
      this.userToken = data.userToken ?? "";
      this.educationalStageToken = data.educationalStageToken ?? "";
      this.userEducationalStageNotes = data.userEducationalStageNotes ?? "";
      this.userEducationalStageImageIsDefault =
        data.userEducationalStageImageIsDefault ?? false;
      this.userEducationalStageImagePath =
        data.userEducationalStageImagePath ?? "";
      this.userEducationalStageImageSizeBytes =
        data.userEducationalStageImageSizeBytes ?? 0;
      this.userEducationalStageImageSizeTextCurrent =
        data.userEducationalStageImageSizeTextCurrent ?? "";
      this.userEducationalStageImageSizeTextAr =
        data.userEducationalStageImageSizeTextAr ?? "";
      this.userEducationalStageImageSizeTextEn =
        data.userEducationalStageImageSizeTextEn ?? "";
      this.userEducationalStageImageSizeTextUnd =
        data.userEducationalStageImageSizeTextUnd ?? "";
      this.userInfoData.fillData(data.userInfoData);
      this.educationalStageInfo.fillData(data.educationalStageInfo);
    } else {
      this.setInitialValue();
    }
  }
}
//#endregion model
