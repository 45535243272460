import { METHOD, request } from "./../utils/request";
import { UserEducationalStages } from "./api";

async function getAll(params) {
  return request(UserEducationalStages.getAll, METHOD.GET, params);
}

export default {
  getAll,
};
