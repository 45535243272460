export default class EducationalStageInfo {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.fullCode = "";
    this.educationalStageToken = "";
    this.educationalStageNameCurrent = "";
    this.educationalStageDescriptionCurrent = "";
    this.educationalStageNotes = "";
    this.educationalStageOrder = 0;
    this.educationalStageImageIsDefault = false;
    this.educationalStageImagePath = "";
    this.educationalStageImageSizeBytes = 0;
    this.educationalStageImageSizeTextCurrent = "";
  }
  fillData(data) {
    if (data) {
      this.fullCode = data.fullCode ?? "";
      this.educationalStageToken = data.educationalStageToken ?? "";
      this.educationalStageNameCurrent = data.educationalStageNameCurrent ?? "";
      this.educationalStageDescriptionCurrent =
        data.educationalStageDescriptionCurrent ?? "";
      this.educationalStageNotes = data.educationalStageNotes ?? "";
      this.educationalStageOrder = data.educationalStageOrder ?? 0;
      this.educationalStageImageIsDefault =
        data.educationalStageImageIsDefault ?? false;
      this.educationalStageImagePath = data.educationalStageImagePath ?? "";
      this.educationalStageImageSizeBytes =
        data.educationalStageImageSizeBytes ?? 0;
      this.educationalStageImageSizeTextCurrent =
        data.educationalStageImageSizeTextCurrent ?? "";
    } else {
      this.setInitialValue();
    }
  }
}
