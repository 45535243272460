<template>
  <div class="">
    <DashboardNavbar />
    <PreLoader v-if="isLoading" />

    <div class="app-content pagination-with-content">
      <template v-if="hasData">
        <div
          class="my-card row custom-card animate__animated animate__bounceIn"
        >
          <span class="my-card-title">{{ $t("students.data") }}</span>
          <DataLabelGroup
            :className="'col-md-4'"
            :value="
              userEducationalStages.userEducationalStagesData[0].userInfoData
                .userNameCurrent
            "
            :title="$t('Users.name')"
            :imgName="'students.svg'"
          />
          <DataLabelGroup
            :className="'col-md-4 phone-number'"
            :value="
              userEducationalStages.userEducationalStagesData[0].userInfoData
                .userPhoneWithCC
            "
            :title="$t('Users.phone')"
            :imgName="'phone.svg'"
          />
          <DataLabelGroup
            :className="'col-md-4'"
            :value="
              userEducationalStages.userEducationalStagesData[0]
                .educationalStageInfo.educationalStageNameCurrent
            "
            :title="$t('EducationalStages.current')"
            :imgName="'educationalStages.svg'"
          />
        </div>

        <UserEducationalStageTable
          class="animate__animated animate__bounceIn"
          :userEducationalStagesData="
            userEducationalStages.userEducationalStagesData
          "
          :defaultImg="userEducationalStages.userEducationalStage.defaultImg"
          :filterData="userEducationalStages.filterData"
        />
      </template>
      <ExceptionWithImg
        v-if="exceptionMsg"
        :msg="exceptionMsg"
        :image="exceptionImg"
      />
      <CustomPagination
        v-if="hasData"
        :paginationData="userEducationalStages.filterData"
        v-on:changePagination="changePagination($event)"
      />
    </div>
  </div>
</template>

<script>
import { STATUS } from "./../../utils/constants";
import DashboardNavbar from "./../../layouts/components/DashboardNavbar.vue";
import PreLoader from "./../../components/general/PreLoader.vue";
import DataLabelGroup from "./../../components/general/DataLabelGroup.vue";
import ExceptionWithImg from "./../../components/general/ExceptionWithImg.vue";
import CustomPagination from "./../../components/general/CustomPagination.vue";
import UserEducationalStageTable from "./components/UserEducationalStageTable.vue";

import apiUserEducationalStage from "./../../api/userEducationalStage";
import UserEducationalStages from "./../../models/userEducationalStages/UserEducationalStages";

import generalMixin from "./../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    DashboardNavbar,
    PreLoader,
    DataLabelGroup,
    ExceptionWithImg,
    CustomPagination,
    UserEducationalStageTable,
  },
  computed: {
    hasData() {
      return this.userEducationalStages.userEducationalStagesData.length != 0;
    },
  },
  props: {
    userToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      userEducationalStages: new UserEducationalStages(),
    };
  },
  methods: {
    changePagination(pagination) {
      this.userEducationalStages.filterData.fillData(pagination);
      this.getAllUserEducationalStages();
    },

    search(data) {
      this.userEducationalStages.filterData.fillData(data);
      this.getAllUserEducationalStages();
    },

    setFilter() {
      this.userEducationalStages.filterData.userToken = this.userToken;
    },
    async getAllUserEducationalStages() {
      this.isLoading = true;
      try {
        this.userEducationalStages.userEducationalStagesData = [];
        const response = await apiUserEducationalStage.getAll(
          this.userEducationalStages.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
        } else if (response.data.status == STATUS.NO_INTERNET) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noInternet.svg";
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = null;
        }
        this.userEducationalStages.fillData(response.data);
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = null;
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.setFilter();
    this.getAllUserEducationalStages();
  },
};
</script>

<style lang="scss" scoped>
.custom-card {
  margin: 10px !important;
  width: calc(100% - 20px) !important;
}
</style>
