import { render, staticRenderFns } from "./UserEducationalStages.vue?vue&type=template&id=0bf88362&scoped=true&"
import script from "./UserEducationalStages.vue?vue&type=script&lang=js&"
export * from "./UserEducationalStages.vue?vue&type=script&lang=js&"
import style0 from "./UserEducationalStages.vue?vue&type=style&index=0&id=0bf88362&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bf88362",
  null
  
)

export default component.exports